.instructions {
	padding: 1em;
	text-align: center;
}

.tap-button {
	margin: 0 auto;
	width: 80%;
	padding-bottom: 1em;
}

.button-title {
	color: #fff;
	font-size: 1.5em;
	text-align: center;
}

.row {
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 0;
}

.card-body {
	padding: .5em;
}