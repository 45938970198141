.helper {
	padding-top: 1em;
	width: 90%;
	margin: 0 auto;
	margin-bottom: 3em;
}

.helper h1,
.helper h3,
.helper h5 {
  text-align: center;
  vertical-align: middle;
}

.helper .form-control {
	text-align: center;
	font-size: 3em;
	height: 2em;
}

.home {
	background-color: #3782c4;
}

.away {
	background-color: #d9534f;
}

.v-align {
	float: none;
	display: inline-block;
	vertical-align: middle;
}

.title {
	margin-top: .5em; 
	padding-right: 1em; 
	text-align: center;
}

.first-row .row {
	margin-top: -15px;	
}

.second-row .row {
	margin-top: 0;	
}

.info {
	color: #428BCA;
	font-size: 2em;
	text-align: left;
	margin-top: .1em; 
}

.donate {
	text-align: center;
}
